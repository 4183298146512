import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SfInitAppService {
  public library:any = {
    name:"SF-LIBRARY",
    appKey:"77458b590a188f57405734b8ba3aa18d"
  }

  public info:any = {
    name:"SUNNATA-DHAMMA-RAMSI",
    appKey:""
  }

  public index:any = {    
    prefixPath: "app,panels",
    route:"/panels/yogi-management",
  }
    
  public env: any = {
    /* production server */    
    apiHost: "https://yogi.back.sunnatadhammaramsi.org",
    appUrl: "https://yogi.sunnatadhammaramsi.org",    
    assetsPath: "/assets/frontend/"

    /* development server */    
    // apiHost: "https://yogi.back.sunnatadhammaramsi.net",
    // appUrl: "https://yogi.sunnatadhammaramsi.net",

    // apiHost: "http://localhost:8000",
    // appUrl: "http://localhost:4200",
    // assetsPath: "/assets/frontend/"

  }
    

  //Pagination
  public contentsPerPage: number = 10;
  public contentsPerReport: number = 300;

  // Access Token  

  //api retry count
  public retryCount: number = 2;

  //toggleButton
  public toogleIcon: string = "fa fa-list"; //"fa fa-angle-left"

  //template
  public template:any = {
    spinner: {
      type: 'ball-clip-rotate-multiple',
      message: 'spinner.message.loading',
    },
    sidebar:{
      backgroundColor: 'bg-dark',    
      textColor: 'text-white',
      submenuBorderClass: 'sf-sidebar-link-white',        
    },
    topbar:{
      backgroundColor: 'bg-dark'
    },
    content:{
      backgroundColor: 'bg-white',
      card:{
        headerBackgroundColor :'bg-dark',
        bodyBackgroundColor: 'bg-white',
      },
      view:'container-fluid'
    },
    newTask:{
      type: "Tab"
    }
  }

  //internet connection
  public connectionStatus = false;

  //button_animation_style
  public button_animation_style = "animate__animated animate__rubberBand";

  //table_animation_style
  public table_animation_style = "animate__animated animate__fadeIn";

  //modal stack 
  public modalStack = [];
  public allowClosingModalWithEscapeKeyFlag = true;     
  //end

  //print panel
  public currentPrintPanel = "";
  public libraryKey = "";

  constructor() { }

  initialization(centralService){    
    centralService.library = this.library;
    centralService.info = this.info;
    centralService.env = this.env;   
    centralService.index = this.index; 
    centralService.contentsPerPage = this.contentsPerPage;
    centralService.contentsPerReport = this.contentsPerReport;
    centralService.retryCount = this.retryCount;
    centralService.toogleIcon = this.toogleIcon;
    centralService.template = this.template;
    centralService.connectionStatus = this.connectionStatus;
    centralService.button_animation_style = this.button_animation_style;
    centralService.table_animation_style = this.table_animation_style;
    centralService.modalStack = this.modalStack;
    centralService.allowClosingModalWithEscapeKeyFlag = this.allowClosingModalWithEscapeKeyFlag;
    centralService.currentPrintPanel = this.currentPrintPanel;
    centralService.libraryAccess =  true;
    centralService.libraryKey = this.libraryKey;
  }
}
